import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {ApogeeFile} from '../apogee-file';

export class ImagePageComponent extends SectionPageComponent<
  ImagePageComponent,
  'image'
> {
  public image: ApogeeFile;

  constructor(el: any = {}) {
    super(
      el,
      'image',
      {hasTitle: true, required: false},
      {hasText: false, required: false},
    );
    this.image = new ApogeeFile(el.image);
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      image: this.image,
    };
  }

  public override getSearchKeys(): string[] {
    return [];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ):ImagePageComponent {
    const res = new ImagePageComponent(this.toJson());

    res.image = this.image;

    return res;
  }
}
